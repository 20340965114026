var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.complaintType.complaintTypeImagePath,
            _vm.complaintType.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.complaintType.complaintTypeImageIsDefault &&
          _vm.checkPrivilege(_vm.hasComplaintTypeDeleteImage())},on:{"changeValue":function($event){_vm.complaintType.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.complaintType.fullCode,"title":_vm.$t('ComplaintTypes.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.complaintType.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintTypeNameAr"),"errors":_vm.errors_complaintTypeNameAr,"value":_vm.complaintType.complaintTypeNameAr,"title":_vm.$t('ComplaintTypes.nameAr'),"imgName":'complaintTypes.svg'},on:{"changeValue":function($event){_vm.complaintType.complaintTypeNameAr = $event;
            _vm.$v.complaintType.complaintTypeNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintTypeNameEn"),"errors":_vm.errors_complaintTypeNameEn,"value":_vm.complaintType.complaintTypeNameEn,"title":_vm.$t('ComplaintTypes.nameEn'),"imgName":'complaintTypes.svg'},on:{"changeValue":function($event){_vm.complaintType.complaintTypeNameEn = $event;
            _vm.$v.complaintType.complaintTypeNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-complaintTypeNotes"),"value":_vm.complaintType.complaintTypeNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.complaintType.complaintTypeNotes = $event}}})],1)]),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }